'use client';

import { IconWrapper, StyledSpinner, getSpinnerColor } from '@/newComponents/Button/Button.styled';
import { SpinnerSize } from '@/newComponents/Spinner/Spinner';
import SvgIcon, { IconKey } from '@/newComponents/SvgIcon/SvgIcon';
import { IconSize } from '@/newComponents/SvgIcon/SvgIcon.styled';
import { ForwardedRef, forwardRef, type ButtonHTMLAttributes, type SyntheticEvent } from 'react';

import classNames from 'classnames';
import { HallonColor } from '@/global/utils/styleTypes';
import { ButtonColor, ButtonSize } from '@/newComponents/Button/Button.utils';
import styles from './Button.module.css';

type CustomButtonProps = {
  ['data-testid']: string;
  asLink?: boolean;
  href?: string;
  centered?: boolean;
  color?: ButtonColor;
  icon?: IconKey;
  iconSize?: IconSize | string;
  isLoading?: boolean;
  label: string | React.ReactNode;
  onClick?: (e: SyntheticEvent) => void;
  size?: ButtonSize;
  textColor?: HallonColor;
  borderColor?: HallonColor;
  transparentBackground?: boolean;
};

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & CustomButtonProps;

const Button = forwardRef((props: ButtonProps, ref: ForwardedRef<HTMLButtonElement | HTMLAnchorElement>) => {
  const {
    'data-testid': dataTestId,
    'aria-label': ariaLabel,
    disabled = false,
    centered = false,
    className,
    color,
    icon,
    iconSize = 'large',
    isLoading,
    label,
    onClick = undefined,
    size = ButtonSize.Auto,
    asLink = false,
    transparentBackground = false,
    href = undefined,
    textColor,
    borderColor,
    ...rest
  } = props;

  const buttonClasses = classNames(
    styles.button,
    color === ButtonColor.Secondary ? styles.secondary : styles.primary,
    {
      [styles.auto]: size === ButtonSize.Auto,
      [styles.small]: size === ButtonSize.Small,
      [styles.fullWidth]: size === ButtonSize.Fullwidth,
      [styles.centered]: centered,
      [styles.disabled]: disabled,
      [styles.loading]: isLoading,
      [styles.transparent]: transparentBackground && color === ButtonColor.Secondary,
    },
    className
  );

  const Element = asLink ? 'a' : 'button';

  return (
    /* @ts-expect-error Todo fix: HtmlLink and HtmlButton not able to resolve fully */
    <Element
      ref={ref as ForwardedRef<HTMLButtonElement & HTMLAnchorElement>}
      aria-label={ariaLabel}
      data-testid={dataTestId}
      className={buttonClasses}
      style={
        {
          '--border-color': borderColor,
          '--foreground-color': textColor,
        } as React.CSSProperties
      }
      onClick={!isLoading && !disabled ? onClick : undefined}
      href={asLink ? href : undefined}
      disabled={disabled}
      type={props.type || 'button'}
      {...rest}
    >
      {icon && !isLoading && (
        <IconWrapper>
          <SvgIcon icon={icon} size={iconSize} />
        </IconWrapper>
      )}
      <span>{label}</span>
      {isLoading && <StyledSpinner size={SpinnerSize.Small} color={getSpinnerColor(color || ButtonColor.Primary)} />}
    </Element>
  );
});

Button.displayName = 'Button';

export default Button;
