import type { CmsPageSeo, Image } from '@/types';
import Head from 'next/head';

import BaseJson from '@/pages/api/cms/base.json';
import { ApiUrl } from '@/constants';

export type SeoProps = {
  data: CmsPageSeo & {
    name?: string;
    url?: string;
    preamble?: string;
    body?: string;
    heroImage?: Image | null;
  };
};

export const getTitle = (data: SeoProps['data']) => {
  const title = data?.pageTitle || data?.name || '';
  const suffix = BaseJson.base.seoSuffix;
  return `${title.substring(0, 55)}${suffix}`;
};

export const getCanonical = (data: SeoProps['data'], baseUrl: string) => {
  const url = data?.url || null;
  if (!url) {
    return ApiUrl.baseUrl;
  }

  const canonical = url.endsWith('/') ? url.slice(0, -1) : url;
  return `${baseUrl}${canonical}`;
};

export const getDescription = (data: SeoProps['data']) => {
  const description = data?.pageDescription || data?.preamble || data?.body?.replace(/(<([^>]+)>)/gi, '') || false;

  if (description) {
    return `${description.substring(0, 159)}`;
  }
  return undefined;
};

export const getRobots = (data: SeoProps['data']) => {
  return data?.pageRobots;
};

export const getImage = (data: SeoProps['data'], baseUrl: string) => {
  const heroImageUrl = data?.heroImage?.url || '';
  const sharingImage = data?.pageSharingImage?.url ? `${baseUrl}${data?.pageSharingImage?.url}` : null;

  const fallbackHeroImage =
    heroImageUrl.length > 0 ? `${baseUrl}${data?.heroImage?.url}?mode=crop&width=1200&height=1200` : null;
  const fallbackImage = `${baseUrl}/static/images/hallon-logotype.png`;
  return sharingImage ?? fallbackHeroImage ?? fallbackImage;
};

const Seo = ({ data }: SeoProps) => {
  const baseUrl = process.env.CMS_RETURN_URL as string;
  const title = getTitle(data);
  const description = getDescription(data);
  const robots = getRobots(data);
  const canonical = getCanonical(data, baseUrl);
  const image = getImage(data, baseUrl);

  return (
    <Head>
      <title key="title">{title}</title>
      <link rel="canonical" href={canonical} key="canonical" />
      <meta property="og:title" content={title} key="ogTitle" />
      <meta property="og:url" content={canonical} key="ogUrl" />
      <meta property="og:image" content={image} key="ogImage" />

      {description && (
        <>
          <meta name="description" content={description} key="metaDescription" />
          <meta property="og:description" content={description} key="ogDescription" />
        </>
      )}
      {robots && <meta name="robots" content={robots} key="robots" />}
    </Head>
  );
};

export default Seo;
