import Spinner from '@/newComponents/Spinner/Spinner';

import styled, { css } from 'styled-components';

import { BorderRadius, Color, NewBorderRadius, spacing } from '@/global/style/variables';
import { FontWeight } from '@/constants';

import { ButtonColor, ButtonSize } from '@/newComponents/Button/Button.utils';
import type { ButtonProps } from './Button';

type StyledButtonProps = {
  $centered: ButtonProps['centered'];
  $textColor: ButtonProps['textColor'];
  $borderColor: ButtonProps['borderColor'];
  $color: ButtonProps['color'];
  $size: ButtonProps['size'];
  $disabled: ButtonProps['disabled'];
  $isLoading: ButtonProps['isLoading'];
  $transparentBackground: ButtonProps['transparentBackground'];
};

export const getSpinnerColor = (color: ButtonColor) => {
  switch (color) {
    case ButtonColor.Primary:
      return Color.MineShaft;
    case ButtonColor.Secondary:
      return Color.Hallon1;
    default:
      return Color.MineShaft;
  }
};

const isLoadingStyle = css`
  pointer-events: none;
  & > span {
    transition: opacity 0.1s ease-in;
    opacity: 0;
  }
`;

const centeredStyle = css`
  display: flex;
  justify-content: center;
  margin: 0 auto;
`;

export const StyledSpinner = styled(Spinner)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

export const IconWrapper = styled.div`
  margin-right: ${spacing.x15};
`;

const transparentSecondaryButton = css`
  color: ${Color.White};
  background: ${Color.Transparent};
  border: 1px solid ${Color.White};

  &:before {
    border-width: 0;
    border-color: ${Color.White};
  }

  &:hover {
    background: ${Color.White}14; // 14 = 8% opacity
  }
  &:active {
    background: ${Color.White}1f; // 1f = 12% opacity
  }

  &:hover,
  &:active {
    &:before {
      border-width: 3px; // compensate for the secondary button border only being 1px
    }
  }

  &:focus-visible {
    color: ${Color.White};
    background: ${Color.Transparent};
    border: 1px solid ${Color.Ocean}; // 1px here and one on the "before" to avoid getting a size-shift when triggering focus

    &:before {
      border: 1px solid ${Color.Ocean};
    }
  }
`;

export const ColorStyle = {
  [ButtonColor.Primary]: css`
    color: ${Color.Hallon1};
    background-color: ${Color.Hallon4};
    border: 4px solid ${Color.Hallon4};

    &:before {
      border-width: 0;
    }

    &:hover {
      background: ${Color.Hallon4Hover};
      border-color: ${Color.Hallon4Hover};
      &:before {
        border-color: ${Color.Hallon4Hover};
      }
    }
    &:active {
      background: ${Color.Hallon4Active};
      border-color: ${Color.Hallon4Active};
      &:before {
        border-color: ${Color.Hallon4Active};
      }
    }
    &:focus-visible {
      color: ${Color.MineShaft};
      background: ${Color.Hallon4};
      border: 2px solid ${Color.Ocean};
      padding-left: calc(${spacing.x3} + 2px);
      padding-right: calc(${spacing.x3} + 2px);

      &:before {
        border: 2px solid ${Color.White};
      }
    }
  `,
  [ButtonColor.Secondary]: css<{ $transparentBackground?: boolean }>`
    color: ${Color.Hallon1};
    background: ${Color.White};
    border: 1px solid ${Color.Hallon1};

    &:before {
      border-width: 0;
      border-color: ${Color.Hallon1};
    }

    &:hover {
      background: ${Color.MineShaftHover};
      &:before {
        border-color: ${Color.Transparent};
      }
    }
    &:active {
      background: ${Color.MineShaftActive};

      &:before {
        border-color: ${Color.Transparent};
      }
    }

    &:hover,
    &:active {
      &:before {
        border-width: 3px; // compensate for the secondary button border only being 1px
      }
    }

    &:focus-visible {
      color: ${Color.MineShaft};
      background: ${Color.White};
      border: 1px solid ${Color.Ocean}; // 1px here and one on the "before" to avoid getting a size-shift when triggering focus

      &:before {
        border: 1px solid ${Color.Ocean};
      }
    }
    ${({ $transparentBackground }) => $transparentBackground && transparentSecondaryButton}
  `,
};

const DisabledSecondaryButtonStyle = css`
  background: ${Color.White};
  color: ${Color.DarkGray2};
  border-color: ${Color.DarkGray2};

  &:hover {
    background: ${Color.White};
    border-color: ${Color.DarkGray2};
  }

  &:before {
    border-color: ${Color.DarkGray2};
  }
`;

export const DisabledStyle = {
  [ButtonColor.Primary]: css`
    cursor: default;
    background: ${Color.Gray1};
    color: ${Color.DarkGray1};
    border-color: ${Color.Gray1};

    &:hover {
      background: ${Color.Gray1};
      border-color: ${Color.Gray1};
    }

    &:before {
      border-color: ${Color.Gray1};
    }
  `,
  [ButtonColor.Secondary]: css<{ $transparentBackground?: boolean }>`
    cursor: default;
    background: ${Color.Transparent};
    color: ${Color.White};
    border-color: ${Color.White};

    &:hover {
      background: ${Color.White};
      border-color: ${Color.White};
    }

    &:before {
      border-color: ${Color.Gray1};
    }
    ${({ $transparentBackground }) => $transparentBackground && DisabledSecondaryButtonStyle}
  `,
};

export const StyledButtonCss = css<ButtonProps>``;

export const StyledButton = styled.button<StyledButtonProps>`
  padding: 2px ${spacing.x3} 0;
  height: 55px;
  border: 2px solid;
  border-radius: ${NewBorderRadius.Large};
  font-weight: ${FontWeight.Medium};
  outline: 0;
  line-height: 1;
  position: relative;
  transition: all, 0.2s ease-out;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${({ $size }: StyledButtonProps) => {
    if ($size === ButtonSize.Small) return 'fit-content';
    if ($size === ButtonSize.Fullwidth) return '100%';
    return 'auto';
  }};
  text-align: ${({ $centered }: StyledButtonProps) => ($centered ? 'center' : 'left')};

  &:before {
    background: none;
    border: 2px solid ${Color.Hallon4};
    border-radius: ${BorderRadius.XLarge};
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
  }

  ${({ $color }: StyledButtonProps) => ColorStyle[$color ?? ButtonColor.Primary]}
  ${({ $disabled, $color }: StyledButtonProps) => $disabled && DisabledStyle[$color ?? ButtonColor.Primary]}
  ${({ $isLoading }: StyledButtonProps) => $isLoading && isLoadingStyle}
  ${({ $centered }: StyledButtonProps) => $centered && centeredStyle}
  ${({ $textColor }) => $textColor && `color: ${$textColor}`};
  ${({ $borderColor }) => $borderColor && `border-color: ${$borderColor}`};
`;
