export enum ButtonColor {
  Primary = 'Primary',
  Secondary = 'Secondary',
}

export enum ButtonSize {
  Auto = 'Auto',
  Fullwidth = 'Fullwidth',
  Small = 'Small',
}
