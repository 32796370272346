'use client';

import Button from '@/newComponents/Button/Button';
import Card from '@/newComponents/Card/Card';
import Divider from '@/newComponents/Divider/Divider';
import Typography from '@/newComponents/Typography/Typography';
import { TypographyType } from '@/newComponents/Typography/Typography.types';
import type { ErrorPage } from '@/types';
import { useRouter } from 'next/navigation';

import { useAppContext } from '@/global/hooks/useAppContext';
import { Color, spacing } from '@/global/style/variables';
import HtmlFromCMS from '@/global/utils/HtmlFromCMS';

import Seo from '@/components/Seo/Seo';

import { ContentWrapper } from './ErrorPage.styled';

type ErrorPageProps = {
  data?: ErrorPage;
  addSeo?: boolean;
};

const ErrorPage = (props: ErrorPageProps) => {
  const { data, addSeo = false } = props;
  const router = useRouter();
  const { isApp } = useAppContext();

  if (!data) return null;

  const buttonLinkData = isApp
    ? {
        ...data.buttonLink,
        url: `/mina-sidor`,
      }
    : data.buttonLink;

  return (
    <ContentWrapper>
      {addSeo && <Seo data={data} />}

      <Divider marginY={spacing.x4} />
      <Typography color={Color.Hallon1} text={data.headline} type={TypographyType.HeadingL} align="center" />
      <Divider marginY={spacing.x2} />
      <Card data-testid="404-card">
        {data.preamble && (
          <>
            <Typography type={TypographyType.ExpressiveS} text={data.preamble} />
            <Divider margin={spacing.x2} />
          </>
        )}

        <Typography text={<HtmlFromCMS html={data.body} />} />

        {buttonLinkData && (
          <>
            <Divider marginY={spacing.x2} />
            <Button
              data-testid="error-page-go-to-button"
              label={buttonLinkData.name}
              onClick={() => router.push(buttonLinkData.url)}
            />
          </>
        )}
      </Card>

      <Divider marginY={spacing.x3} />
    </ContentWrapper>
  );
};

export default ErrorPage;
