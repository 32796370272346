import styled, { css } from 'styled-components';

import { Color } from '@/global/style/variables';

export const SpinnerContainer = styled.div<{
  $size: number;
  $strokeWidth: number;
  $color: string;
  $haloWidth: number;
  $haloSize: number;
  $haloOffset: number;
}>(({ $size, $color, $haloWidth, $haloSize, $haloOffset }) => {
  return css`
    position: relative;
    display: block;
    width: ${$size}px;
    height: ${$size}px;
    color: ${$color};

    &:before {
      content: '';
      display: block;
      box-sizing: border-box;
      border: ${$haloWidth}px solid ${Color.White};
      border-radius: 50%;

      width: ${$haloSize}px;
      height: ${$haloSize}px;
      position: relative;
      top: ${$haloOffset}px;
      left: ${$haloOffset}px;
    }

    .circular-loader {
      animation: rotate 1s linear infinite;
      height: 100%;
      transform-origin: center center;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      margin: auto;
    }

    .loader-path {
      stroke-dasharray: 150, 200;
      stroke-dashoffset: -10;
      animation: dash 8s ease-in-out infinite;
      stroke-linecap: round;
    }

    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }

    @keyframes dash {
      0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
      }
      50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35;
      }
      100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124;
      }
    }
  `;
});
