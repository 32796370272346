'use client';

import { Color } from '@/global/style/variables';
import { HallonColor } from '@/global/utils/styleTypes';

import { SpinnerContainer } from './Spinner.styled';

export enum SpinnerSize {
  Mini = 'mini',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

const presets = {
  [SpinnerSize.Mini]: {
    diameter: 18,
    strokeWidth: 5,
    haloWidth: 1.5,
    haloSize: 16,
    haloOffset: 1,
  },
  [SpinnerSize.Small]: {
    diameter: 28,
    strokeWidth: 4,
    haloWidth: 2,
    haloSize: 24,
    haloOffset: 2,
  },
  [SpinnerSize.Medium]: {
    diameter: 60,
    strokeWidth: 6,
    haloWidth: 7,
    haloSize: 55,
    haloOffset: 2.5,
  },
  [SpinnerSize.Large]: {
    diameter: 120,
    strokeWidth: 6,
    haloWidth: 14,
    haloSize: 110,
    haloOffset: 5,
  },
};

export type SpinnerProps = {
  size?: SpinnerSize;
  className?: string;
  color?: HallonColor;
};

const Spinner = (props: SpinnerProps) => {
  const { className, size = SpinnerSize.Medium, color = Color.Ocean } = props;
  const { diameter, strokeWidth, haloWidth, haloSize, haloOffset } = presets[size];

  return (
    <SpinnerContainer
      className={className}
      $size={diameter}
      $strokeWidth={strokeWidth}
      $color={color}
      $haloWidth={haloWidth}
      $haloSize={haloSize}
      $haloOffset={haloOffset}
    >
      <svg className="circular-loader" viewBox="25 25 50 50">
        <circle
          className="loader-path"
          cx="50"
          cy="50"
          r="20"
          fill="none"
          stroke="currentColor"
          strokeWidth={strokeWidth}
        />
      </svg>
    </SpinnerContainer>
  );
};

export default Spinner;
