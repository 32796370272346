import { css } from 'styled-components';

import { ContentWidth, GridMargins, above } from '@/global/style/breakpoint';

export const GridLargeWrapper = css`
  width: 100%;
  margin: 0 auto;
  max-width: calc(2 * ${GridMargins.lg} + ${ContentWidth.lg});

  padding-left: ${GridMargins.xxs};
  padding-right: ${GridMargins.xxs};

  ${above.xs`
    padding-left: ${GridMargins.xs};
    padding-right: ${GridMargins.xs};
  `}

  ${above.sm`
    padding-left: ${GridMargins.sm};
    padding-right: ${GridMargins.sm};
  `}

  ${above.md`
    padding-left: ${GridMargins.md};
    padding-right: ${GridMargins.md};
  `}

  ${above.lg`
    padding-left: ${GridMargins.lg};
    padding-right: ${GridMargins.lg};
  `}
`;

export const GridSmallWrapper = css`
  width: 100%;
  margin: 0 auto;
  max-width: calc(2 * ${GridMargins.xs} + ${ContentWidth.sm});

  padding-left: ${GridMargins.xxs};
  padding-right: ${GridMargins.xxs};

  ${above.xs`
    padding-left: ${GridMargins.xs};
    padding-right: ${GridMargins.xs};
  `}
`;
