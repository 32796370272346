import styled from 'styled-components';

import { GridMargins, above } from '@/global/style/breakpoint';
import { GridSmallWrapper } from '@/global/style/extends/Grid';
import { spacing } from '@/global/style/variables';

export const ContentWrapper = styled.div`
  ${GridSmallWrapper};
  display: block;
  position: relative;
  z-index: 1;
  width: 100%;
  min-height: 300px;
  margin: 0 auto;

  padding-bottom: 0;
  padding-left: ${GridMargins.xs};
  padding-right: ${GridMargins.xs};

  ${above.sm`
    padding-left: 0;
    padding-right: 0;
  `}

  margin-bottom: ${spacing.x7};
  ${above.md`
    margin-bottom: ${spacing.x10};
  `}
`;
