'use client';

import { Brand, HallonContext } from '@/types';
import { ReactNode, createContext, useMemo, useState } from 'react';

export const defaultContext = {
  account: null,
  setAccount: () => {},
  userId: null,
  userEmail: null,
  accounts: undefined,
  hasActiveCart: false,
  pageBrand: Brand.B2C,
  loginMethod: null,
  isApp: false,
  roles: [],
};

export const AppContext = createContext<HallonContext | undefined>(defaultContext);

type ContextProviderProps = {
  children: ReactNode;
  context: Omit<HallonContext, 'setAccount'>;
};

const ContextProvider = (props: ContextProviderProps) => {
  const { userId, userEmail, accounts, hasActiveCart, pageBrand, loginMethod, isApp, roles } = props.context;
  const [account, setAccount] = useState(props.context.account);

  const value = useMemo(() => {
    const userIdVal = account?.userId ?? userId;

    return {
      account,
      setAccount,
      userEmail,
      accounts,
      hasActiveCart,
      pageBrand,
      loginMethod,
      isApp,
      userId: userIdVal ?? null,
      roles,
    };
  }, [account, setAccount, userEmail, accounts, hasActiveCart, pageBrand, loginMethod, isApp, userId, roles]);

  return <AppContext.Provider value={value}>{props.children}</AppContext.Provider>;
};

ContextProvider.displayName = 'HallonContextProvider';

export default ContextProvider;
